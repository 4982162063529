import React, { useState } from "react";
import { useLocation, navigate } from "@reach/router";
import queryString from "query-string";
// import { injectIntl } from "react-intl";
// import { isSSR } from "../api/utils";

import { Intro } from "./landing";

const VideoOrPlayList = React.lazy(() =>
  import("./common/DownloadCard/VideoOrPlayList")
);

const App = ({ titleContent }) => {
  /** Api **/
  let [loading, setloading] = useState(false);
  let [videoInfo, setVideoInfo] = useState(false);
  let [disabelBtn, setDisabelBtn] = useState(false);

  const changeDisabel = (trueOrFalse) => {
    setDisabelBtn(trueOrFalse);
  };

  let [errorMessage, setErrorMessage] = useState(false);
  let [retried, setretried] = useState(false);
  let [info, setInfo] = useState("");

  const location = useLocation();
  const { url } = queryString.parse(location.search);
  let [inputValue, setInputValue] = useState(url ?? "");

  const updateInput = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      navigate(location.pathname);
    }
    setInputValue(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (inputValue !== "") {
      const query = queryString.stringify({ url: inputValue });
      navigate(inputValue ? `?${query}` : location.pathname);
      getVideoInfo(inputValue);
    }
  };
  const getVideoInfo = (url) => {
    if (videoInfo) setVideoInfo(false);
    setloading(true);
    import("../api").then(({ api }) => {
      api
        .retry(
          // Function to be retried
          () => {
            // Send the API request, 15 minutes timeout
            return api.enqueueTask({ type: "info", url }, 90000);
          },
          // Retry options
          {
            times: 1,
            timeout: 3000,
            retryCallback: () => {
              setretried(true);
              setTimeout(function() {
                setretried(false);
              }, 3000);
              console.log("Retrying...");
            },
          }
        )
        .then((data) => {
          // console.log('SUCCESS: ', data);
          setInfo(data);
          setVideoInfo(true);
          import("../api/utils").then(({ scrollTo }) => {
            scrollTo("#downloadVideo");
          });
        })
        .catch((error) => {
          if (videoInfo) {
            setVideoInfo(false);
          }
          setErrorMessage(true);
          setTimeout(function() {
            setErrorMessage(false);
          }, 3500);
          console.error("ERROR: ", error);
        })
        // Using instead of Promise.finally()
        .then(() => {
          // Stop loading indicators
          setloading(false);
        });
    });
  };

  return (
    <>
      <Intro
        submitForm={submitForm}
        inputValue={inputValue}
        updateInput={updateInput}
        loading={loading}
        disabelBtn={disabelBtn}
        retried={retried}
        errorMessage={errorMessage}
        titleContent={titleContent}
      />
      <div id="downloadVideo">
      {videoInfo && (
        <React.Suspense fallback={<div />}>
          <VideoOrPlayList
            dataInfo={info.result}
            parentFunctionForDisabel={changeDisabel}
            disabelBtn={disabelBtn}
          />
        </React.Suspense>
      )}
      </div>
    </>
  );
};

export default App;
